export const languages = [
  "fr_BE",
  "nl_BE",
  "nl_NL",
  "en_GB",
  "de_DE",
  "it_IT",
  "es_ES",
  "pl_PL",
  "ro_RO",
  "pt_PT",
  "fr_CH",
  "de_CH",
  "fr_FR",
  "hu_HU",
  "tr_TR",
  "cs_CZ",
];
